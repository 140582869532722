import React, {
    FormEvent,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    FormElement,
    FormElementType,
} from "../../components/shared/Form/FormElementsFactory";
import { encode, orderLocations, slugify, getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";
import { messageIcons } from "../../shared/icons";
import { SelectOption } from "../../components/shared/CustomSelect";
import Helmet from "../../components/Helmet";
import Hero from "../../components/Widgets/HeroMedia";
import Banner from "../../components/Widgets/TextBanner";
import StaffGrid from "../../components/Widgets/StaffGrid";
import GradientImageBanner from "../../components/Widgets/GradientImageBanner";
import Form from "../../components/shared/Form";
import SubmitPopup from "../../components/shared/Form/SubmitPopup";
import styles from "./trainers.module.scss";
import PopUp from "../../components/shared/PopUpStaff";
import WarningComponent from "../../components/shared/FallbackWarnComponent";
import { graphql } from "gatsby";
import { useDependency } from "../../contexts/DependencyContext";
import { ValidationService } from "../../services/ValidationService";
import { isEmpty } from "lodash";
import DashboardApiService from "../../services/DashboardApiService";
import formatters from "../Account_create_page/components/Form/formatters";
import Spinner from "../../components/shared/Spinner";
interface TrainersPageProps {
    pageContext: any;
    navigate: any;
}

interface Popup {
    icon: ReactElement;
    title: string;
    message: string;
}

const TrainersPage = (
    props: PropsWithChildren<TrainersPageProps>
): ReactElement => {
    const { pageContext } = props;
    const { data, relations } = pageContext;
    const { try_us } = relations;
    const bannerProps = {
        banner_title: try_us.dataRaw.title,
        banner_text: try_us.dataRaw.subtitle,
        banner_background: try_us.dataRaw.background_image,
        banner_cta_text: try_us.dataRaw.cta_text,
        banner_cta_url: try_us.dataRaw.cta_url,
        banner_gradient_color: try_us.dataRaw.grandient_color,
        banner_text_color: try_us.dataRaw.text_color,
    };

    const popupRef = useRef<HTMLDivElement>(null as any);
    const [selectedStaff, setSelectedStaff] = useState<any>(null);
    let locations = props.data.allPrismicLocations.edges.map(edge => edge.node.data.location);

    locations = orderLocations(locations);

    //#region form
    const contactFormElements = [
        {
            type: FormElementType.Select,
            name: "gym",
            generateHiddenForm: true,
            placeholder: data.dropdown_gyms_label
                ? data.dropdown_gyms_label[0]?.text
                : "Please select a club",
            options: locations.map((x: any) => ({
                label: x.name,
                id: x.businessUnitCode,
                selected: false,
            })),
        },
        {
            type: FormElementType.Radio,
            name: "training-group",
            options: data.group_selector.map((x: any, i: number) => ({
                label: x.option[0]?.text,
                value: x.value[0]?.text || x.option[0]?.text,
                defaultChecked: i === 0,
            })),
            groupTraining: true
        },
        {
            type: FormElementType.Text,
            name: "first-name",
            placeholder:"First Name",
            errorMessage: "Invalid name format",
            validations: {
                text: true
            }
        },
        {
            type: FormElementType.Text,
            name: "last-name",
            placeholder: "Last Name",
            errorMessage: "Invalid name format",
            validations: {
                text: true
            }
        },
        {
            type: FormElementType.Email,
            name: "email",
            placeholder: data.email_input_label
                ? data.email_input_label[0]?.text
                : "Email",
            errorMessage: "Invalid email format",
            validations: {
                remote: {
                    val: "validate/email",
                    customError: "We couldn't verify your email address, please check your input"
                }
            },
        },
        {
            type: FormElementType.Tel,
            name: "phone",
            placeholder: data.phone_input_label
                ? data.phone_input_label[0]?.text
                : "Phone",
            errorMessage: "Invalid phone format",
            formatter: "phoneNum",
            validations: {
                tel: true,
                remote: {
                    val: "validate/phone",
                    customError: "We couldn't validate your phone number, please check your input"
                }
            },
        },
        {
            type: FormElementType.Checkbox,
            name: "consent",
            placeholder: data.consent_input_label ? data.consent_input_label[0]?.text : "",
        },
    ];

    const [formData, setFormData] = useState<any>({});
    const [formElements, setFormElements] = useState<FormElement[]>(contactFormElements as any);
    const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
    const [popup, setPopup] = useState<Popup>(null as any);
    const [formErrors, setFormErrors] = useState({});
    const [isLoad, setIsLoad]= useState<boolean>(false)
    const validationService = useDependency(ValidationService);
    const errorsRef = useRef({});

    const handleInputChange = ({ target }: FormEvent): void => {
        const input = target as HTMLInputElement | HTMLTextAreaElement;

        const formElement = contactFormElements.find(fElem => fElem.name === target.name);

        let value = input.value;
        
        if (formElement?.formatter) {
            const formatterObj = formatters[formElement.formatter];
            const Formatter = formatterObj.class;
            let formatterInstance;

            if (formatterObj.instances[input.name]) {
                formatterInstance = formatterObj.instances[input.name];
            } else {
                formatterObj.instances[input.name] = new Formatter();
                formatterInstance = formatterObj.instances[input.name];
            }

            value = formatterInstance.format(input.value);
        }

        setFormData({
            ...formData,
            [input.name]:
                input.type === FormElementType.Checkbox
                    ? (input as HTMLInputElement).checked
                    : value,
        });
    };

    const handleBlurValidation = async ({ target }: FormEvent): void => {
        const errors = {};

        const { name, type, validations } = formElements.find(
            e => e.name === target.name
        );

        if (validations) {
            for (const key in validations) {
                const validationValue = validations[key];
                const value = formData[name];
                let isValid = false;

                if (validations["optional"] && !value) {
                    break;
                }

                isValid = await validationService.setValidation(
                    value,
                    key,
                    validationValue,
                    name
                );

                if (!isValid) {
                    if (!errors[name]) {
                        errors[name] = [];
                    }

                    let customMsg = "";

                    if (typeof validationValue === "object") {
                        if (validationValue.customError) {
                            customMsg = validationValue.customError;
                        }
                    }

                    const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                    errors[name][0] = errMsg;
                    errorsRef.current[name] = errMsg;                   }
            }
        }

        if (!isEmpty(errors)) {
            setFormErrors({
                ...formErrors,
                ...errors,
            });
        } else {
            const _formErrors = { ...formErrors };

            delete _formErrors[name];

            setFormErrors(_formErrors);
        }
    };

    const skipValidation = (name, value) => {
        const arrCopy = [...formElements];
        const optional = arrCopy.find(e => e.name === name);
        optional.validations.skip = value;        
        optional.validations.optional = value;
        const errors = {...formErrors};

        if (value) {
            delete errors[name];
        } else {
            errors[name] = errorsRef.current[name];
        }

        setFormErrors(errors)
        setFormElements(arrCopy);
    } 

    const handleSelectChange = (name: string, value: string): void => {
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmission = async (e: FormEvent): void => {
        e.persist();
        e.preventDefault();
        setIsLoad(true);

        const form = e.target as HTMLFormElement;
        const recaptchaResponse = grecaptcha.getResponse();
        const selectOptions = formElements.filter(x => x.options)[0];
        const selectedForm = selectOptions.options?.filter(
            option => option.selected
        )[0];
        const errors = {};

        setRecaptchaError(!recaptchaResponse);

        if (!recaptchaResponse) {
            setIsLoad(false);
            return;
        }

        if (formData) {
            for (const formElem of formElements) {
                const { name, type, validations } = formElem;

                if (validations) {
                    if (validations.skip) break;

                    for (const key in validations) {
                        const validationValue = validations[key];
                        const value = formData[name];
                        let isValid = false;

                        isValid = await validationService.setValidation(
                            value,
                            key,
                            validationValue,
                            name
                        );

                        if (!isValid) {
                            if (!errors[name]) {
                                errors[name] = [];
                            }

                            let customMsg = "";

                            if (typeof validationValue === "object") {
                                if (validationValue.customError) {
                                    customMsg = validationValue.customError;
                                }
                            }

                            const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                            errors[name][0] = errMsg;
                            errorsRef.current[name] = errMsg;  
                        }
                    }
                }
            }

            if (isEmpty(errors)) {
                const postData = {
                    name: "trainers",
                    businessUnitCode: locations.find(l => l.name === formData.gym).businessUnitCode,
                    formData: formData,
                    recaptchaToken: recaptchaResponse,
                };

                const response = await DashboardApiService.submitForm(postData);

                if (response.data.success){
                    setIsLoad(false);
                    props.navigate("/trainers/thank-you");
               } else{
                setIsLoad(false);
                    setPopup({
                        icon: messageIcons.error,
                        title: "Something went wrong",
                        message: "Please try again",
                    });}
            } else {
                setIsLoad(false);
                setFormErrors(errors);
            }
        }
    };

    useEffect(() => {
        if (!popup) return;
        setTimeout(() => window.location.reload(), 3000);
    }, [popup]);

    useEffect(() => {
        const radio = contactFormElements.find(
            x => x.type === FormElementType.Radio
        ) as any;
        const defaultChecked = radio.options.find((x: any) => x.defaultChecked);
        setFormData({ ...formData, [radio.name]: defaultChecked.value });
    }, []);
    //#endregion

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Trainers")}
                description={getSeoDescription(data, "Trainers")}
                keywords={getSeoKeywords(data, "")} 
            >
                <script
                    src="https://www.google.com/recaptcha/api.js"
                    async
                    defer
                ></script>
            </Helmet>

            <Hero data={data} height={80} />

            {data.banner_text ? (
                <Banner data={data} />
            ) : (
                <WarningComponent
                    template={`Trainers page`}
                    message={"Banner"}
                />
            )}

            {/* Contact Form */}

            <div className={styles.formWrapper}>
                <div
                    className={styles.leftSection}
                    style={{
                        backgroundImage: `url(${data.background_image?.url})`,
                    }}
                >
                    <div className={styles.imageTitles}>
                        {data.contact_text.length > 0 ? (
                            data.contact_text.map((x: any, i: number) => (
                                <p key={i}>{x.text}</p>
                            ))
                        ) : (
                            <WarningComponent
                                template={`Trainers page`}
                                message={"Form text"}
                            />
                        )}
                    </div>
                </div>

                <div className={styles.rightSection}>
                    <Form
                        name="trainers-contact"
                        title="Contact Us"
                        handleBlurValidation={handleBlurValidation}
                        formErrors={formErrors}
                        data={formData}
                        elements={formElements}
                        recaptchaError={recaptchaError}
                        {...{
                            handleInputChange,
                            handleSelectChange,
                            handleFormSubmission,
                            skipValidation
                        }}
                    />
                </div>

                {popup && (
                    <SubmitPopup
                        icon={popup.icon}
                        title={popup.title}
                        message={popup.message}
                        handleClose={() => window.location.reload()}
                    />
                )}
            </div>

            {/* End Contact form */}

            {selectedStaff && (
                <PopUp
                    ref={popupRef}
                    {...{ selectedStaff, setSelectedStaff }}
                />
            )}

            {relations.personal_trainers.length > 0 ? (
                <StaffGrid {...relations} setStaff={setSelectedStaff} />
            ) : (
                <WarningComponent
                    template={`Trainers page`}
                    message={"Staff"}
                />
            )}

            {try_us.dataRaw ? (
                <GradientImageBanner data={{ ...bannerProps }} />
            ) : (
                <WarningComponent
                    template={`Trainers page`}
                    message={"Try us widget"}
                />
            )}

            {isLoad && <Spinner></Spinner>}
        </>
    );
};

export const query = graphql`
    query {
        allPrismicTryUs {
            edges {
                node {
                    dataString
                }
            }
        }
        allPrismicLocations {
            edges {
                node {
                    id
                    data {
                        location {
                            businessUnitCode
                            name
                            division
                        }
                    }
                }
            }
        }
    }
`;

export default TrainersPage;
